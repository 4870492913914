import React, { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import { ref, onValue, update } from "firebase/database";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  Modal,
  Box,
  Stack,
  useTheme,
} from "@mui/material";

import Header from "../../../../components/header/Header";
import {
  decodeTokenRefresh,
  getClientToken,
  getTokenRefresh,
  decodeClientToken,
} from "../../../../helpers/token/refreshToken";
import { generateOpenPayURL } from "../../../../api/openpay/openpay";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../../theme";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import HeaderPdf from "./pdf/elements/Header";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import GridDataTable from "./table/GridDataTable";
import DocumentName from "./pdf/elements/DocumentName";
import Rider from "./pdf/elements/Rider";
import TableRider from "./pdf/elements/TableRider";
import Contact from "./pdf/elements/Contact";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import { getAdvanceCashOrder } from "../../../../api/booking/advance";
import { GenerateOpenpayUseCase } from "../../../../api/openpay/GenerateOpenpayUseCase";
import SnackBar from "../../../../components/snackbar/SnackBar";

const HistoryBookingLater = ({ client }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const model = ["", "Sedan", "SUV", "Minivan"];
  const currency = ["", "PEN", "USD"];
  const curren = {
    1: "PEN",
    2: "USD",
  };
  const [open, setOpen] = React.useState(false);
  const [uid, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState([]);
  const handleClose = () => setOpen(false);

  const token = getTokenRefresh();
  const id = decodeTokenRefresh(token);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const starCountRef = ref(db, `bookingNow/`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return setBooking([]);

      setBooking([]);
      Object.values(data).map((book) => {
        if (book.client === id && book.booking_type != 1 && book.cotizado) {
          book.currency = curren[book.currency];
          book.fullorigin = book.origin.name;
          book.fulldestination = book.destination.name;
          setBooking((oldBooking) => [...oldBooking, book]);
        }
      });
    });
  }, []);

  //traer todos los pagos que tiene en mysql
  useEffect(() => {
    setListAdvance([]);
    if (booking.length > 0) {
      booking.map(async (item, idx) => {
        const orderNumber = item.counterBooking;
        const resp = await getAdvanceCashOrder(orderNumber);
        console.log("listAdvance", resp.data.resp);
        const data = resp.data.resp;
        if (data.length > 0) {
          const list = {
            orderNumber,
            data,
          };
          console.log(list);
          setListAdvance((oldData) => [...oldData, list]);
        }
      });
    }
  }, [booking]);
  //
  const [listAdvance, setListAdvance] = useState([]);

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agregar un cero inicial si es necesario
    var dia = ("0" + fecha.getDate()).slice(-2); // Agregar un cero inicial si es necesario
    var hora = ("0" + fecha.getHours()).slice(-2); // Agregar un cero inicial si es necesario
    var minuto = ("0" + fecha.getMinutes()).slice(-2); // Agregar un cero inicial si es necesario
    var segundo = ("0" + fecha.getSeconds()).slice(-2); // Agregar un cero inicial si es necesario

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const generateOpenPayForm = async (booking) => {
    try {
      setIsLoading(true);
      const baseUrl = "https://booking.taximundo.com/client";
      // const baseUrl = "http://localhost:3001/client";
      const redirectUrl = `${baseUrl}/transfer/${booking.currency}`;

      const dataToken = getClientToken();
      const { name, lastname, phone, email } = decodeClientToken(dataToken);
      const orderID = `${booking.counterBooking}-${Date.now()}`;
      const bookingOpenPay = {
        method: "card",
        amount: Number(booking.advance),
        currency: booking.currency,
        description: `${booking.counterBooking}. PAGO POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(), //"2023-12-09T19:22:00",
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await GenerateOpenpayUseCase(bookingOpenPay);

      if (response.message === "success") {
        const paymentUrl = response.data.payment_method.url;
        const transactionId = response.data.id;
        const orderId = response.data.order_id;
        window.open(paymentUrl, "_blank");
        const openpayData = {
          transaction_id: transactionId,
          order_id: orderId,
          createBy: "B",
        };
        const fbUrl = `bookingNow/${booking.uuid}/openpay`;
        await update(ref(db, fbUrl), openpayData);
      } else {
        showSnackbar("ERROR: OPENPAY", "error");
      }
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpen(false);
    }
  };

  const [openPdf, setOpenPdf] = useState(false);
  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenPdf(true);
  };
  const handleCloseModal = () => setOpenPdf(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: "100%",
    p: 2,
    overflow: "scroll",
  };
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [dataSelected, setDataSelected] = useState([]);

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    let total = 0;
    let discount = 0;
    let advance = 0;

    Object.values(dataSelected).map((book) => {
      console.log(book);
      total += Number(book.total);
      discount += Number(book.discount);
      advance += Number(book.advance ? book.advance : 0);
    });

    setTableData({});
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: dataSelected,
      igv: 0,
      total: total,
      discount,
      advance,
      peaje: 0,
      subTotal: 0,
      from: 0,
      to: 0,
    });
  }, [dataSelected]);

  const columns = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { counterBooking } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {counterBooking}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "clientName",
      width: 130,
    },
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "ORIGEN",
      field: "fullorigin",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "fulldestination",
      width: 250,
    },
    {
      headerName: "TOTAL",
      field: "total",
      width: 100,
    },
  ];

  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const handleCloseSnk = () => setStateSnk(false)

  const showSnackbar = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={""} subtitle={t("bookings")} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOpenModal}
            sx={{ marginRight: 1 }}
          >
            <CloudDownloadOutlinedIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <FiberNewOutlinedIcon /> {t("create new order")}
          </Button>
        </Box>
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardHeader
              sx={{ mt: -1 }}
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  TM-{booking.counterBooking}
                </Typography>
              }
              action={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  {booking.date} - {booking.time}
                </Typography>
              }
            />
            <CardContent sx={{ mt: -4 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>

                {listAdvance.length > 0 &&
                  listAdvance.map((item, index) => {
                    const check =
                      Number(booking.counterBooking) ==
                      Number(item.orderNumber);

                    if (check) {
                      return (
                        <div key={index}>
                          <div style={{ width: "100%", fontWeight: "bold" }}>
                            Adelanto:
                          </div>
                          {item.data.map((adv, idx) => {
                            return (
                              <div
                                key={idx}
                                style={{ width: "100%", fontWeight: "bold" }}
                              >
                                {idx + 1}.- {adv.currency} {adv.amount}{" "}
                                {adv.status} {adv.operation_date}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  })}
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <div
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "50px",
                      fontSize: 15,
                    }}
                  >
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {booking.openpay ? (
                      booking.openpay?.status === "pending" ? (
                        "PENDIENTE"
                      ) : (
                        <div style={{ display: "block" }}>
                          <p style={{ fontSize: 11, color: "green" }}>PAGADO</p>
                          <p style={{ fontSize: 11 }}>
                            {booking.currency} {booking.advance}
                          </p>
                        </div>
                      )
                    ) : (
                      "PENDIENTE"
                    )}
                  </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <div
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "50px",
                      fontSize: 15,
                    }}
                  >
                    <p style={{ fontSize: 11, color: "green" }}>Precio</p>
                    <p>
                      {booking.currency} {booking.total}
                    </p>
                  </div>
                </Grid>

                {booking.discount && booking.discount > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        borderStyle: "solid",
                        borderColor: "black",
                        borderWidth: 1,
                        textAlign: "center",
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "50px",
                        fontSize: 15,
                      }}
                    >
                      <p style={{ fontSize: 11, color: "green" }}>Descuento</p>
                      <p>
                        {booking.currency} {booking.discount}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                {booking.advance && booking.advance > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        borderStyle: "solid",
                        borderColor: "black",
                        borderWidth: 1,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "50px",
                        fontSize: 15,
                        display: "block",
                      }}
                    >
                      <p style={{ fontSize: 11, color: "green" }}>Adelanto</p>
                      <p>
                        {booking.currency} {booking.advance}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{
                      height: "50px",
                      fontWeight: "bold",
                      background: "#128C7E",
                    }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${booking.clientName},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      console.log(booking.uuid);
                      setUid(booking.uuid);
                    }}
                  >
                    Consultar&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>
                {booking.advance > 0 &&
                  booking.openpay &&
                  booking.openpay.btnPay && (
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ height: "50px", fontWeight: "bold" }}
                        onClick={(e) => {
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        PAGAR&nbsp;
                        <ContactlessOutlinedIcon />
                      </Button>

                      {/* CREAR LA FUNCIONALIDAD PARA PAGAR */}
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            PAGAR PEDIDO?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              disabled={isLoading}
                              onClick={(e) => {
                                generateOpenPayForm(bookingLater);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>
                  )}
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("no_bookings")}</div>
      )}

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleCloseSnk}
        severity={severity}
        messageSnk={messageSnk}
      />

      {/* MODAL PDF SELECT */}
      <Modal open={openPdf} onClose={handleCloseModal}>
        <Box
          height={"100%"}
          sx={styleModal}
          backgroundColor={colors.primary[400]}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                Lista
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <button
                style={{
                  backgroundColor: "red",
                  borderRadius: 15,
                  width: 30,
                  height: 30,
                  border: "none",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseModal();
                }}
              >
                X
              </button>
            </Grid>

            {dataSelected.length > 0 && (
              <Grid item xs={12} sm={12} md={2} textAlign="end">
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page size="A4" style={styles.page}>
                        <HeaderPdf />
                        {/* <Title title="Taxi Mundo PERU" /> */}
                        <Contact invoice={tableData} />
                        <DocumentName
                          title={"COTIZACION"}
                          from={tableData.from}
                          to={tableData.to}
                        />
                        <Rider invoice={tableData} />
                        <TableRider invoice={tableData} />
                      </Page>
                    </Document>
                  }
                  fileName={"detalles-reservas-taximundo.pdf"}
                >
                  <Box width={"100%"}>
                    <Button fullWidth variant="contained" color="success">
                      <PictureAsPdfIcon /> DESCARGAR
                    </Button>
                  </Box>
                </PDFDownloadLink>
              </Grid>
            )}
            <Grid item xs={12}>
              {booking.length > 0 ? (
                <GridDataTable
                  data={booking}
                  checkboxSelection={true}
                  columns={columns}
                  setDataSelected={setDataSelected}
                />
              ) : (
                <>LISTA DE RESERVAS VACIA</>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryBookingLater;
