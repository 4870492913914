import { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import { ref, onValue, remove, update, endAt } from "firebase/database";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  Modal,
  Box,
  Stack,
  useTheme,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Header from "../../../../components/header/Header";
import {
  decodeTokenRefresh,
  getTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../../../helpers/date/convertDate";
import GridDataTable from "./table/GridDataTable";
import { tokens } from "../../../../theme";
import HeaderPdf from "./pdf/elements/Header";
import Title from "./pdf/elements/Title";
import Contact from "./pdf/elements/Contact";
import DocumentName from "./pdf/elements/DocumentName";
import Rider from "./pdf/elements/Rider";
import TableRider from "./pdf/elements/TableRider";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";

const ListQuote = ({ client }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const model = ["", "Sedan", "SUV", "Minivan"];
  // const curren = ["", "PEN", "USD"];

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [uid, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState();
  const handleClose = () => setOpen(false);

  const token = getTokenRefresh();
  const id = decodeTokenRefresh(token);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const curren = {
    1: "PEN",
    2: "USD",
  };

  useEffect(() => {
    try {
      // const laterPromise = new Promise((resolve, reject) => {
      //   let orders = [];
      //   const starCountRef = ref(db, `bookingNow/`);
      //   onValue(starCountRef, (snapshot) => {
      //     const data = snapshot.val();
      //     if (data !== null) {
      //       // setBooking([]);
      //       Object.values(data).map((_book) => {
      //         if (
      //           _book.client === id &&
      //           _book.booking_type != 1 &&
      //           _book.statusPayment != "PAGADO" &&
      //           !_book.cotizado
      //         ) {
      //           _book.currency = currency[_book.currency];
      //           _book.fullorigin = _book.origin.name;
      //           _book.fulldestination = _book.destination.name;
      //           orders.push(_book);
      //         }
      //       });
      //     }
      //   });

      //   resolve(orders);
      // });

      // const hourPromise = new Promise((resolve, reject) => {
      //   let orders = [];
      //   const perHor = ref(db, "bookingNow");
      //   onValue(perHor, (snapshot) => {
      //     const data = snapshot.val();
      //     if (data !== null) {
      //       Object.values(data).map((_booking) => {
      //         if (
      //           (_booking.booking_type === 3 || _booking.booking_type === 4) &&
      //           _booking.client == id
      //         ) {
      //           _booking.currency = currency[_booking.currency];
      //           _booking.fullorigin = _booking.origin.name;
      //           _booking.fulldestination = _booking.destination.name;
      //           orders.push(_booking);
      //         }
      //       });
      //     }
      //   });

      //   resolve(orders);
      // });

      // Promise.all([laterPromise, hourPromise]).then((ordersList) => {
      //   setBooking([]);
      //   const compareByDate = (a, b) => {
      //     const dateB = convertDate(b.date, b.time);
      //     const dateA = convertDate(a.date, a.time);
      //     return dateB - dateA;
      //   };
      //   ordersList[0]
      //     .concat(ordersList[1])
      //     .sort(compareByDate)
      //     .map((bookingQuote) => {
      //       console.log(bookingQuote);
      //       setBooking((oldArray) => [...oldArray, bookingQuote]);
      //     });
      // });

      const starCountRef = ref(db, `bookingNow/`);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          console.log(data);
          const compareByDate = (a, b) => {
            if (b.date != undefined && a.date != undefined) {
              const dateB = convertDate(b.date, b.time);
              const dateA = convertDate(a.date, a.time);
              return dateB - dateA;
            }
          };
          setBooking([]);
          Object.values(data)
            .sort(compareByDate)
            .map((_book) => {
              if (
                _book.client === id &&
                _book.booking_type != 1 &&
                _book.statusPayment != "PAGADO" &&
                !_book.cotizado
              ) {
                _book.currency = curren[_book.currency];
                _book.fullorigin = _book.origin.name;
                _book.fulldestination = _book.destination.name;
                setBooking((oldBooking) => [...oldBooking, _book]);
              }
            });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [dataSelected, setDataSelected] = useState([]);
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: "100%",
    p: 2,
    overflow: "scroll",
  };

  const columns = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { counterBooking } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {counterBooking}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "clientName",
      width: 130,
    },
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "ORIGEN",
      field: "fullorigin",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "fulldestination",
      width: 250,
    },
    {
      headerName: "TOTAL",
      field: "total",
      width: 100,
    },
  ];
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    let total = 0;
    let discount = 0;
    let advance = 0;

    Object.values(dataSelected).map((book) => {
      total += Number(book.total);
      discount += Number(book.discount);
      advance += Number(book.address ? book.advance : 0);
    });

    setTableData({});
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: dataSelected,
      igv: 0,
      total: total,
      discount,
      advance,
      peaje: 0,
      subTotal: 0,
      from: 0,
      to: 0,
    });
  }, [dataSelected]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("")} subtitle={t("quotation")} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleOpenModal()}
            sx={{ marginRight: 1 }}
          >
            <CloudDownloadOutlinedIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <FiberNewOutlinedIcon /> {t("create new order")}
          </Button>
        </Box>
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardHeader
              sx={{ mt: -1 }}
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  TM-{booking.counterBooking}
                </Typography>
              }
              action={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  {booking.date} - {booking.time}
                </Typography>
              }
            />
            <CardContent sx={{ mt: -4 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>

                {booking.dateEnd && booking.timeEnd && (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Hasta: {booking.dateEnd} {booking.timeEnd}
                    </Typography>
                  </Grid>
                )}

                {booking.tour_description && (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Tour: {booking.tour_description}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                    &nbsp;
                    <DriveEtaOutlinedIcon />
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <div
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    <p style={{ fontSize: 11, color: "green" }}>Precio</p>
                    <p>
                      {booking.currency} {booking.total}
                    </p>
                  </div>
                </Grid>
                {booking.discount && booking.discount > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        border: "1px solid black",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "100%",
                        fontSize: 14,
                        display: "block",
                      }}
                    >
                      <p style={{ fontSize: 11, color: "green" }}>Descuento</p>
                      <p>
                        {booking.currency} {booking.discount}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                {booking.advance && booking.advance > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        borderStyle: "solid",
                        borderColor: "black",
                        borderWidth: 1,
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "100%",
                        fontSize: 14,
                        display: "block",
                      }}
                    >
                      <p style={{ fontSize: 11, color: "green" }}>Adelanto</p>
                      <p>
                        {booking.currency} {booking.advance}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                {Number(booking.total) === 0 && !booking.cotizado ? (
                  <Grid item xs={2}></Grid>
                ) : (
                  <>
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ height: "100%", fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        CONFIRMAR
                      </Button>

                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            ¿CONFIRMAR RESERVA?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={async (e) => {
                                const dbUrl = `bookingNow/${uid}`;
                                await update(ref(db, dbUrl), {
                                  cotizado: true,
                                });
                                setOpen(false);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>

                    {/* <Grid item xs={2} sm={3} md={2}> */}
                    {/* <Button
                        fullWidth
                        size="small"
                        color="error"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          setOpenDelete(true);
                          setUid(booking.uuid);
                        }}
                      >
                        RECHAZAR
                      </Button> */}
                    <Modal
                      open={openDelete}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          textAlign={"center"}
                        >
                          Desea Eliminar esta Reserva?
                        </Typography>
                        <Stack direction="row" spacing={2}>
                          <Button
                            fullWidth
                            size="small"
                            color="primary"
                            variant="contained"
                            sx={{ fontWeight: "bold" }}
                            onClick={(e) => {
                              console.log(uid);
                              const starCountRef = ref(db, `bookingNow/${uid}`);
                              remove(starCountRef);
                              setOpenDelete(false);
                            }}
                          >
                            ELIMINAR
                          </Button>
                          <Button
                            fullWidth
                            size="small"
                            color="error"
                            variant="contained"
                            sx={{ fontWeight: "bold" }}
                            onClick={(e) => {
                              setOpenDelete(false);
                            }}
                          >
                            CANCELAR
                          </Button>
                        </Stack>
                      </Box>
                    </Modal>
                    {/* </Grid> */}

                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="info"
                        variant="contained"
                        sx={{
                          height: "100%",
                          fontWeight: "bold",
                          background: "#128C7E",
                        }}
                        href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${client.name} ${client.lastname},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name}`}
                        target={"_blank"}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setUid(booking.uuid);
                        }}
                      >
                        Consultar&nbsp;
                        <WhatsAppIcon />
                      </Button>
                    </Grid>
                  </>
                )}
                {/* <Grid
                  item
                  xs={4}
                  md={6}
                  textAlign="end"
                  alignItems={"end"}
                  onClick={async (e) => e.preventDefault()}
                >
                  <Button>Pending</Button>
                </Grid> */}
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("no_quotation")}</div>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          height={"100%"}
          sx={styleModal}
          backgroundColor={colors.primary[400]}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                Lista
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <button
                style={{
                  backgroundColor: "red",
                  borderRadius: 15,
                  width: 30,
                  height: 30,
                  border: "none",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseModal();
                }}
              >
                X
              </button>
            </Grid>

            <Grid item xs={12}>
              {booking.length > 0 ? (
                <>
                  {dataSelected.length > 0 && (
                    <Grid item xs={12} sm={12} md={2} textAlign="end">
                      <PDFDownloadLink
                        document={
                          <Document>
                            <Page size="A4" style={styles.page}>
                              <HeaderPdf />
                              <Contact invoice={tableData} />
                              <DocumentName
                                title={"COTIZACION"}
                                from={tableData.from}
                                to={tableData.to}
                              />
                              <Rider invoice={tableData} />
                              <TableRider invoice={tableData} />
                            </Page>
                          </Document>
                        }
                        fileName={"detalles-cotizacion-taximundo.pdf"}
                      >
                        <Box width={"100%"}>
                          <Button fullWidth variant="contained" color="success">
                            <PictureAsPdfIcon /> DESCARGAR
                          </Button>
                        </Box>
                      </PDFDownloadLink>
                    </Grid>
                  )}
                  <GridDataTable
                    data={booking}
                    checkboxSelection={true}
                    columns={columns}
                    setDataSelected={setDataSelected}
                  />
                </>
              ) : (
                <>NO HAY COTIZACIONES</>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ListQuote;
